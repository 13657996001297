import styles from "./styles.module.css";
import Coin from "@/assets/icons/coin.svg?react";
import coinImg from "@/assets/icons/coin.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { TaskItem } from "./TaskItem";
import { missionStore, storyManagerStore } from "@/store";
import { observer } from "mobx-react-lite";
import { formatNumber } from "@/utils";
import { SuccessToast } from "@/store/Mission";

export const Earn = observer(() => {
  const handleCheck = (id: number) => {
    const item = missionStore.getById(id);

    const toastData: SuccessToast = {
      text: `+${formatNumber(item?.reward ?? 0)}`,
      icon: coinImg,
    };

    if (item) {
      if (item?.action === "LINK" || item?.action === "CHANNEL") {
        console.warn("item?.action go", item?.action);
        item.go(true, toastData);
      } else {
        console.warn("Else condition", item?.action);
        item.isDone === false && item.check(true, toastData);
      }
    }
  };

  return (
    <div
      style={{
        overflow: "scroll",
      }}
    >
      <div className={styles.title}>
        <Coin width={64} height={64} />
        <p className={styles.coinsText}>Earn more Coins</p>
      </div>
      <div className={styles.dailyTasksWrapper}>
        <p className={styles.tasksTitle}>Daily Tasks</p>
        <div
          style={{
            height: 112,
            display: "flex",
            gap: 4,
            paddingRight: "16px",
          }}
        >
          {missionStore
            .getByType("DAILY")
            .sort((i) => (i.isDone ? 1 : -1))
            .map((item) => {
              return (
                item && (
                  <TaskItem
                    key={item.name}
                    id={item.id}
                    onClick={() => handleCheck(item.id)}
                    taskIcon={item.icon}
                    type="block"
                    title={item.name}
                    reward={item.reward}
                    isCollected={item.isDone}
                  />
                )
              );
            })}
        </div>
      </div>
      <div className={styles.tasksWrapper}>
        <Swiper slidesPerView={"auto"}>
          <SwiperSlide
            style={{
              width: "92%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <p className={styles.tasksTitle}>Onboarding Tasks</p>
            <div className={styles.tasksList}>
              {missionStore
                .getByType("ONBOARDING")
                .sort((i) => (i.isDone ? 1 : -1))
                .map((item) => {
                  return (
                    item && (
                      <TaskItem
                        key={item.name}
                        id={item.id}
                        onClick={() => {
                          if (item.id === 9) {
                            storyManagerStore.showStorySet("onboarding");
                          } else if (item.id === 10) {
                            storyManagerStore.showStorySet("onboardingSui");
                          } else if (item.id === 11) {
                            storyManagerStore.showStorySet("onboardingZlog");
                          }
                          handleCheck(item.id);
                        }}
                        taskIcon={item.icon}
                        type="inline"
                        title={item.name}
                        reward={item.reward}
                        isCollected={item.isDone}
                      />
                    )
                  );
                })}
              <div
                style={{
                  minHeight: "92px",
                }}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <p className={styles.tasksTitle}>Social Tasks</p>
            <div className={styles.tasksList}>
              {missionStore
                .getByType("SOCIAL")
                .sort((i) => (i.isDone ? 1 : -1))
                .map((item) => {
                  return (
                    item && (
                      <TaskItem
                        key={item.name}
                        id={item.id}
                        type="inline"
                        onClick={() => handleCheck(item.id)}
                        taskIcon={item.icon}
                        title={item.name}
                        reward={item.reward}
                        isCollected={item.isDone}
                      />
                    )
                  );
                })}
              <div
                style={{
                  minHeight: "92px",
                }}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
});
