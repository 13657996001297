import styles from "./styles.module.sass";
import "swiper/css";
import { ReferralLevelList } from "./ReferralLevelsList";
import { ReferralUser } from "./ReferralUser";
import { Button } from "@/components/Button";
import { observer } from "mobx-react-lite";
import { inviteShareLink } from "@/constants";
import { friendStore, leagueStore } from "@/store";
import { findLeagueConfig } from "@/utils/leagueConfig";
import ReferralIcon from "@/assets/capybara-images/referral.svg?react";
import clsx from "clsx";

export const Referral = observer(() => {
  const { friends, loading, loaded } = friendStore;
  const userId = Telegram.WebApp.initDataUnsafe.user?.id;
  const link = inviteShareLink.replace("{userId}", btoa(userId + ""));
  const handleShareClick = () => {
    if (Telegram?.WebApp) {
      Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${link}&text=%0AJoin me on the Capybara clicker game!%0A🎉 Let's earn coins and have fun together!%0AUse my invite link to start playing now and claim your rewards!`
      );
    }
  };
  return (
    <div
      style={{
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        paddingBottom: "90px",
      }}
    >
      <div className={styles.title}>
        <p className={styles.coinsText}>Referral</p>
        <ReferralLevelList />
      </div>
      <div className={styles.referButonsContainer}>
        <Button
          styleType="primary"
          label="Refer a Friend"
          onClick={() => handleShareClick()}
        />
        <p className={styles.referButonsHint}>
          Please note, only friends who have created
          <br /> Sui wallets will be counted as successful referrals.
        </p>
        {/* <Button styleType="secondary" label="Join Team" /> */}
      </div>
      <div className={styles.dailyTasksWrapper}>
        <div className={styles.friendsTitleWrapper}>
          <p className={styles.tasksTitle}>Invited Friends</p>
          <p className={styles.friendsCount}>{friends.length}</p>
        </div>

        <div
          className={clsx(styles.referralWrapper, {
            [styles.referralWrapperWithOutUsers]: friends?.length === 0,
          })}
        >
          {friends?.length === 0 && loaded && (
            <div className={styles.blockedLeagueContainer}>
              <div className={styles.blockedLeagueImgContainer}>
                <ReferralIcon width={56} height={56} />
              </div>
              <p className={styles.blockedLeagueText}>No invited friends yet</p>
            </div>
          )}
          {loading && (
            <div className={styles.loaderContainer}>
              <div className={styles.spinner}></div>
            </div>
          )}
          {friends &&
            loaded &&
            friends?.length > 0 &&
            friends.slice(0, 6)?.map((item) => {
              const currentUserLeague = leagueStore.leagues.find(
                (l) => l.level === item?.leagueLevel
              );

              const img = findLeagueConfig(
                currentUserLeague?.leagueNameEn ?? ""
              )?.leagueFace;

              return (
                img &&
                currentUserLeague && (
                  <ReferralUser
                    key={item.username + img}
                    img={img}
                    userName={item.username}
                    leagueName={currentUserLeague?.leagueNameEn}
                  />
                )
              );
            })}
        </div>
      </div>
    </div>
  );
});
