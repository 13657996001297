import { useEffect, useState } from "react";
import { Transaction } from "@mysten/sui/transactions";
import { MIST_PER_SUI } from "@mysten/sui/utils";
import {
  ConnectModal,
  useCurrentAccount,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { isIOS } from "@/utils/handleExpandPage";

export const StashedTransfer = () => {
  const query = new URLSearchParams(window.location.search);
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);
  // const [isFirstAuth, setIsFirstAuth] = useState(true);
  const currentAccount = useCurrentAccount();

  const transfer = () => {
    setIsLoader(true);

    if (
      query.has("reciever") &&
      query.has("amount") &&
      query.has("sender") &&
      !transacrionInWork
    ) {
      setTransacrionInWork(true);
      const txb = new Transaction();
      const amount = Number(query.get("amount")) * Number(MIST_PER_SUI);
      const [coin] = txb.splitCoins(txb.gas, [amount]);
      txb.setSender(query.get("sender") as string);
      txb.transferObjects([coin], query.get("reciever") as string);
      signAndExecuteTransaction(
        {
          transaction: txb,
          chain: "sui:mainnet",
        },
        {
          onSuccess: () => {
            if (isIOS) {
              const url = new URL(window.location.href);
              url.search = "";
              window.history.replaceState(null, "", url.toString());
              window.open("https://t.me/getcapybot", "_self");
            } else {
              window.close();
            }
            setIsLoader(false);
          },
        }
      );
    }
  };

  useEffect(() => {
    localStorage.removeItem("stashed:recentAddress");
    localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
  }, []);

  useEffect(() => {
    if (currentAccount?.address) {
      if (isIOS) {
        document.getElementById("transferButton")?.click();
      }
      setStashedWalletIsOpen(false);
    }
  }, [currentAccount]);

  return (
    <>
      {isLoader && <Loader />}
      {query.has("amount") && (
        <ConnectModal
          open={stashedWalletIsOpen}
          trigger={<></>}
          onOpenChange={() => {
            console.log();
          }}
        />
      )}
      {query.has("amount") && !isIOS && (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={!stashedWalletIsOpen}
          onClose={() => {
            if (isIOS) {
              window.open("https://t.me/getcapybot", "_self");
            } else {
              window.close();
            }
          }}
          onAction={() => transfer()}
          title={"Connecting is finished"}
          text={"Click the button below to approve the transaction"}
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Approve transaction"}
        />
      )}
      <button
        style={{
          opacity: 0,
        }}
        onClick={transfer}
        id="transferButton"
      >
        Transfer
      </button>
    </>
  );
};
