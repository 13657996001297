import React from "react";
import styles from "./styles.module.sass";
import Vector from "@/assets/capybara-images/Vector.svg?react";
import Coin from "@/assets/icons/coin.svg?react";
import { renderTextWithLineBreaks } from "@/utils/renderTextWithLineBreaks";
import clsx from "clsx";

type typeProps = "inline" | "block" | undefined;

interface TaskProps {
  taskIcon?: string;
  id: number;
  title: string;
  hint?: string;
  reward: number;
  isCollected?: boolean;
  isLoading?: boolean;
  type?: typeProps;
  onClick?: () => void;
}

export const TaskItem: React.FC<TaskProps> = ({
  taskIcon,
  isCollected = true,
  title,
  hint,
  reward,
  isLoading,
  type,
  onClick,
}) => {
  const renderTaskType = (type: typeProps) => {
    const backgroundColor = isCollected
      ? "#4DA2FF"
      : "rgba(255, 255, 255, 0.16)";

    if (type === "block") {
      return (
        <div
          onClick={() => {
            onClick && onClick();
          }}
          className={clsx(styles.taskItemBlock, {
            [styles.loading]: isLoading,
          })}
          style={{ background: backgroundColor }}
        >
          <div className={styles.taskBlockInfoContainer}>
            <img src={taskIcon} alt="taskIcon" />
            {!isCollected && (
              <div className={styles.vectorIcon}>
                <Vector width={6} height={12} />
              </div>
            )}
          </div>
          <div className={styles.taskBlockRewardContainer}>
            <div className={styles.taskBlockTextContainer}>
              <p className={styles.title}>{renderTextWithLineBreaks(title)}</p>
            </div>
            <div
              className={
                isCollected ? styles.revardCollected : styles.revardAmount
              }
            >
              {isCollected ? (
                <p>🎉 Done</p>
              ) : (
                <>
                  <p>{`+${reward.toLocaleString("en-US")}`}</p>
                  <Coin width={16} height={16} />
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            onClick && onClick();
          }}
          className={clsx(styles.taskItemInline, {
            [styles.loading]: isLoading,
          })}
          style={{ background: backgroundColor }}
        >
          <div className={styles.taskInlineInfoContainer}>
            <img src={taskIcon} alt="taskIcon" />
            <div className={styles.taskInlineTextContainer}>
              <p className={styles.title}>{renderTextWithLineBreaks(title)}</p>
              {hint && <p className={styles.hint}>{hint}</p>}
            </div>
          </div>
          <div className={styles.taskInlineRewardContainer}>
            <div
              className={
                isCollected ? styles.revardCollected : styles.revardAmount
              }
            >
              {isCollected ? (
                <p>🎉 Done</p>
              ) : (
                <>
                  <p>{`+${reward.toLocaleString("en-US")}`}</p>
                  <Coin width={16} height={16} />
                </>
              )}
            </div>
            {!isCollected && (
              <div className={styles.vectorIcon}>
                <Vector width={6} height={12} />
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return renderTaskType(type);
};
