import { action, computed, observable } from "mobx";
import type RootStore from "./RootStore";
import Mission, { MissionOptions } from "./Mission";
import { TaskOption } from "@/services/types";

export default class MissionStore {
  rootStore: RootStore;
  @observable private accessor missions: Map<number, Mission> = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  hardcodeOption(option: TaskOption): MissionOptions | false {
    const id = option.externalId;
    const result = { ...option, id };
    return result;
  }

  @action init(taskOptions: TaskOption[]) {
    this.missions = new Map();
    taskOptions.forEach((option) => {
      const missionOption = this.hardcodeOption(option);
      if (missionOption) {
        const mission = new Mission(missionOption);
        this.missions.set(missionOption.id, mission);
        //setTimeout(()=>mission.check(), 2000);
      }
    });
  }

  @action initState(state: number[]) {
    state.forEach((id) => {
      const mission = this.getById(id);
      if (mission) {
        mission.initState(true);
      } else {
        console.warn("Unknown mission id in user data");
      }
    });
  }

  @computed public get all() {
    return [...this.missions.values()]
      .filter((m) => m.show)
      .sort((a, b) => a.id - b.id);
  }

  getById(id: number) {
    return this.missions.get(id);
  }

  getByType(type: string) {
    return this.all.filter(
      (mission) => mission.type === type
    );
  }
}
