import { action, computed, observable, flow } from "mobx";
import type RootStore from "./RootStore";
import { FriendResponse, FriendResponseFriend } from "@/services/types";
import { backend } from "@/services";

export interface Friend extends FriendResponseFriend {}

export default class FriendStore {
  rootStore: RootStore;
  @observable.ref private accessor _friends: Friend[] = [];
  @observable public accessor loading: boolean = false;
  @observable public accessor loaded: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action init() {
    this._friends = [];
  }

  @action initState() {
    this._friends = [];
    this.loaded = false;
  }

  @computed public get friends() {
    if (!this.loading && !this.loaded) {
      setTimeout(() => this.refresh(), 0);
    }
    const clone = structuredClone(this._friends);
    const sorted = clone.sort((a, b) => a.leagueLevel! - b.leagueLevel!);
    return sorted;
  }

  @flow.bound *refresh() {
    this.loading = true;
    const response: FriendResponse = yield backend.sendFriends();
    this._friends = response.friends;
    this.loading = false;
    this.loaded = true;
  }
}
