import { action, flow, observable } from "mobx";
import type RootStore from "./RootStore";
import { backend } from "@/services";
import { balance } from "@/utils";

export default class WalletStore {
  rootStore: RootStore;
  @observable public accessor isCreated: boolean = false;
  @observable public accessor enokiAddress: string | null = "";
  @observable public accessor enokiHash: string | null = "";
  @observable public accessor stashedAddress: string | null = "";
  @observable public accessor balance: string | number = "";
  @observable public accessor usdBalance: string | number = "";
  @observable public accessor isBalanceLoading: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const item = localStorage.getItem("stashed:recentAddress");
    if (item) {
      this.isCreated = item ? true : false;
      this.stashedAddress = item ?? "";
      localStorage.setItem(
        "sui-dapp-kit:wallet-connection-info",
        JSON.stringify({
          state: {
            lastConnectedWalletName: "Stashed",
            lastConnectedAccountAddress: item,
          },
          version: 0,
        })
      );
    }
  }

  @action.bound chageCreateStatus(value: boolean) {
    this.isCreated = value;
  }

  @action.bound setStashedAddress(value: string) {
    this.stashedAddress = value;
  }

  @action.bound setEnokiHash(value: string) {
    this.enokiHash = value;
  }

  @action.bound setBalance(value: string | number) {
    this.balance = value;
    this.getUsd();
  }

  @action.bound getUsd() {
    backend.getSuiCost().then((res) => {
      this.usdBalance = Number(balance(this.balance)) * res.data.rates.USD;
    });
  }

  @flow.bound *getUsdBalance() {
    yield;
  }
}
