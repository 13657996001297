import React from "react";
import styles from "./styles.module.css";
import useHapticFeedback from "@/hooks/useHapticFeedback";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  styleType?: "primary" | "secondary";
  disabled?: boolean;
  block?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  styleType = "primary",
  disabled = false,
  block = false,
}) => {
  const triggerHapticFeedback = useHapticFeedback();

  const buttonStyles = `${styles.button} ${styles[styleType]} ${
    disabled ? styles.disabled : ""
  } ${block ? styles.block : styles.nonBlock}`;

  return (
    <button
      className={buttonStyles}
      onClick={
        disabled
          ? undefined
          : () => {
              triggerHapticFeedback();
              onClick && onClick();
            }
      }
      disabled={disabled}
    >
      {label}
    </button>
  );
};
