import styles from "./styles.module.css";
import { CoinsDisplay } from "@/components/CoinsDisplay";
import { Capybara, tap, click } from "@/components/Capybara";
import { observer } from "mobx-react-lite";
import EnergyBar from "@/components/EnergyBar";
import { boostTapStore, gameStore, leagueStore, walletStore } from "@/store";
import Rocket from "@/components/Rocket";
import { capybaraLevels } from "@/constants";
import { CreateWallet } from "@/components/CreateWallet/CreateWallet";
import { Wallet } from "@/components/Wallet";

import { TutorialOverlay } from './TutorialOverlay';

export const Feed = observer(() => {
  const { level } = leagueStore;
  const { balance, totalBalance } = gameStore;

  return (
    <div className={styles.feedContainer}>
      {boostTapStore.isRocket && <Rocket />}
      <div className={styles.coinsBlockWrapper}>
        {walletStore.isCreated ? (
          <Wallet
            backgroundColor={capybaraLevels[level].walletBackgroundColor}
          />
        ) : (
          <CreateWallet
            backgroundColor={capybaraLevels[level].walletBackgroundColor}
          />
        )}

        <CoinsDisplay />
        {/* <Team /> */}
      </div>
      <div className={styles.capybaraContainer}>
        <Capybara level={level} />
        <EnergyBar />
      </div>
      <TutorialOverlay isNewbie={!balance && !totalBalance} onClick={click} onTap={tap} />
    </div>
  );
});
